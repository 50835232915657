import React, { ReactElement, useCallback, useMemo } from 'react';
import { Table, TableColumnsType, theme, Collapse, Typography } from 'antd';
import dayjs from 'dayjs';

import { useBoundPollsStore } from '@store/slices/polls';
import { IPollItem } from '@services/Polls/interfaces';
import { ETypeInput } from '@src/interfaces/common';

import { getCountByProgressValue } from './utils';
import Chart from './Chart';
import { IExtendedAnswerItem } from './interfaces';

interface IProps {
  selectedPeriod: 'day' | 'week' | 'month';
  dataTable: IExtendedAnswerItem[];
}

const StatisticsTable = ({ dataTable, selectedPeriod }: IProps): ReactElement => {
  const {
    token: { colorWarningBg, colorSuccessBg, colorErrorBg },
  } = theme.useToken();
  const { items: pollsItems } = useBoundPollsStore(({ items, isLoading }) => ({
    items,
  }));

  const getChildredTable = useCallback(
    (items: IPollItem[]) => {
      if (!dataTable.length) {
        return <Typography.Paragraph>Нет данных по выброному периоду</Typography.Paragraph>;
      }

      const data = items.map(({ title, key, type, max }) => {
        const values = dataTable.reduce<Record<string, any>>((acc, { day, fields }) => {
          let text = fields[key];

          if (type === ETypeInput.Switch && selectedPeriod === 'day') {
            text = fields[key] ? 'Да' : 'Нет';
          }

          const current = {
            value: Number(fields[key]) || 0,
            about: fields[`${key}.about`],
            text,
          };

          acc[day] = { ...current };

          return acc;
        }, {});

        return { name: title, type, max, ...values };
      });

      const columns: TableColumnsType = [
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
          fixed: true,
          width: 280,
        },
        ...dataTable.map(({ day, count }) => ({
          title: selectedPeriod === 'day' ? dayjs(day, 'DD.MM.YY').format('DD.MM') : day,
          dataIndex: day,
          key: day,
          render: (curr: any, rec: any) => {
            let color = '';
            const backgroundType = getCountByProgressValue({
              type: rec.type,
              max: rec.max * (count ?? 1),
              value: Number(curr.value),
            });

            if (backgroundType === 'warning') {
              color = colorWarningBg;
            }

            if (backgroundType === 'danger') {
              color = colorErrorBg;
            }

            if (backgroundType === 'success') {
              color = colorSuccessBg;
            }

            return (
              <div style={{ textAlign: 'center' }}>
                <div
                  style={{
                    background: color,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  }}
                />
                <div style={{ zIndex: 1, position: 'relative' }}>
                  {curr.text} <br />
                  {curr.about}
                </div>
              </div>
            );
          },
        })),
      ];

      return (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="name"
          scroll={{ x: true }}
          expandable={{
            expandedRowRender: (record) => {
              const dataChart = Object.keys(record).reduce<Array<{ name: string; value: number }>>(
                (acc, key) => {
                  if (key === 'max' || key === 'name' || key === 'type') {
                    return acc;
                  }

                  // @ts-ignore
                  acc.push({
                    name: selectedPeriod === 'day' ? dayjs(key, 'DD.MM.YY').format('DD.MM') : key,
                    value: record[key]?.value || 0,
                  });

                  return acc;
                },
                []
              );

              return (
                <div>
                  <Chart data={dataChart} />
                </div>
              );
            },
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
        />
      );
    },
    [dataTable, selectedPeriod]
  );

  const collapseItems = pollsItems.map(({ title, key, children }) => ({
    key,
    label: title,
    children: getChildredTable(children),
  }));

  return <Collapse items={collapseItems} />;
};

export default StatisticsTable;
